@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-Light.eot');
  src: url('./fonts/Nationale-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nationale-Light.woff2') format('woff2'),
    url('./fonts/Nationale-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-LightItalic.eot');
  src: url('./fonts/Nationale-LightItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/Nationale-LightItalic.woff2') format('woff2'),
    url('./fonts/Nationale-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-Regular.eot');
  src: url('./fonts/Nationale-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nationale-Regular.woff2') format('woff2'),
    url('./fonts/Nationale-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-Italic.eot');
  src: url('./fonts/Nationale-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nationale-Italic.woff2') format('woff2'),
    url('./fonts/Nationale-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-Medium.eot');
  src: url('./fonts/Nationale-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nationale-Medium.woff2') format('woff2'),
    url('./fonts/Nationale-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-MediumItalic.eot');
  src: url('./fonts/Nationale-MediumItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/Nationale-MediumItalic.woff2') format('woff2'),
    url('./fonts/Nationale-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-DemiBold.eot');
  src: url('./fonts/Nationale-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Nationale-DemiBold.woff2') format('woff2'),
    url('./fonts/Nationale-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('./fonts/Nationale-DemiBoldItalic.eot');
  src: url('./fonts/Nationale-DemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/Nationale-DemiBoldItalic.woff2') format('woff2'),
    url('./fonts/Nationale-DemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
