@import "core";
@import "grid";

body {
  color: #181c56;
  font-family: "Nationale", sans-serif;
}

#main {
  display: flex;
  margin: 3rem;
}

.room {
  transition: all 300ms;

  &:hover,
  &:focus,
  &--focused {
    .room-popup {
      display: block;
    }
  }
}

.room-popup {
  display: none;
  position: relative;
  bottom: 0;
  border-radius: 0.25rem;
  width: fit-content;
  background-color: white;
  color: #181c56;
  box-shadow: 0 0.125rem 1rem 0 rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  text-align: left;
  z-index: 200;

  img {
    max-width: 400px;
    border-radius: 0.25rem;
  }
}

.panel {
  margin-right: 2rem;
}

.desks {
  display: flex;
}

.chair {
  border: 2px solid #54568c;
  width: 1rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 1px;

  &:hover,
  &:focus,
  &--focused {
    background-color: #ff5959;
    border-color: #ff5959;

    .person {
      display: block;
    }
  }

  &--holiday {
    background-color: #ffcece;
    border-color: #d31b1b;
  }
}

.person {
  display: none;
  position: relative;
  bottom: 0;
  border: 2px solid white;
  border-radius: 2px;
  width: fit-content;
  background-color: #181c56;
  color: white;
  min-width: 10rem;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  text-align: left;
  z-index: 100;

  &__id {
    font-size: 12px;
    float: right;
  }

  .holiday {
    font-size: 12px;
    margin-top: 2px;
  }
}

header {
  color: #181c56;
}

.floors > h2 {
  float: right;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  opacity: 0.5;
}

input {
  background-color: #f8f8f8;
  border: 0;
  border-bottom: 2px solid #181c56;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.65625rem 1rem 0.53125rem 1rem;
}

.repo-link {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media only screen and (max-width: 770px) {
  #main {
    display: block;
    margin: 0.5rem;
  }
}
