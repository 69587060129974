@import "core";

.seventh.floor,
.third.floor,
.fourth.floor {
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(57, 1rem) 1rem;
  grid-template-rows: repeat(25, 1rem) 1rem;
  max-width: 52rem;
  // background-image: url('/entur-plantegning.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;

  > div {
    position: relative;
  }
}

.block {
  background: repeating-linear-gradient(
    45deg,
    #656782,
    #656782 2px,
    #fff 0,
    #fff 8px
  );
  border: 4px solid $color-border;
}

.desk {
  border: 2px solid #54568c;
  height: 1.5rem;
}

.desk--half {
  border: 2px solid #54568c;
  height: 0.75rem;
}

.chair {
  border: 2px solid #54568c;
  width: 1rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 1px;

  &:hover,
  &:focus,
  &--focused {
    background-color: #ff5959;
    border-color: #ff5959;

    .person {
      display: block;
    }
  }
}

.chair-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desk-with-chairs-vertical {
  display: flex;

  .chair-row {
    flex-direction: column;
  }

  .chair {
    height: 1rem;
    width: 0.5rem;
  }

  .desk {
    height: unset;
    width: 2rem;
  }

  .desk--half {
    height: unset;
    width: 1rem;
  }
}

.name {
  transition: 200ms all;
}

.module-west {
  grid-column: 11 / span 15;
  grid-row: 13 / span 5;
  border-width: 4px;
}

.hallway {
  grid-column: 9 / span 5;
  grid-row: 1 / span 5;
  border-width: 4px 4px 4px 0;
}

.fourth.floor > .hallway {
  border-width: 4px;
}

.hallway-stairs {
  grid-column: 11 / span 3;
  grid-row: 6 / span 3;
  border-width: 0 4px 4px;
}

.hallway-2 {
  grid-column: 48 / span 11;
  grid-row: 13 / span 7;
  border-width: 4px;
}

.outer-border {
  border: 4px solid $color-border;
  grid-column: 1 / span 58;
  grid-row: 5 / span 24;
  border-width: 0 4px 4px 4px;
}

.room {
  border: 4px solid $color-border;
  padding: 0.25rem;

  &:hover {
    background-color: #aeb7e2;

    .name {
      color: transparent;
    }
  }

  &.blatur {
    grid-column: 1 / span 8;
    grid-row: 1 / span 6;
    z-index: 2;
    border-width: 4px;
  }

  &.langtur {
    grid-column: 1 / span 8;
    grid-row: 1 / span 10;
    z-index: 2;
    border-width: 4px;
  }

  &.salg {
    padding-top: 0.5rem;
    grid-column: 1 / span 8;
    grid-row: 1 / span 8;
    z-index: 3;
    border-width: 4px 0 0 4px;

    .space {
      margin-top: 1rem;
    }

    .desk-with-chairs {
      width: 100%;
    }
  }

  &.varelager {
    grid-column: 1 / span 8;
    grid-row: 9 / span 5;
    z-index: 2;
    border-width: 0 0 0 4px;

    .desk-with-chairs {
      width: 100%;
    }
  }

  &.kunde {
    grid-column: 1 / span 8;
    grid-row: 14 / span 4;
    z-index: 1;
    border-width: 0 0 0 4px;

    .desk-with-chairs {
      width: 100%;
    }
  }

  &.topptur {
    grid-column: 1 / span 10;
    grid-row: 22 / span 5;
    border-width: 0 0 4px 4px;
  }

  &.drommetur {
    grid-column: 1 / span 8;
    grid-row: 18 / span 10;
    border-width: 4px;
  }

  &.lobby {
    grid-column: 1 / span 10;
    grid-row: 7 / span 15;
    border-width: 0 0 4px 4px;
  }

  &.ror {
    grid-column: 20 / span 7;
    grid-row: 5 / span 6;
    border-width: 4px 4px 0 0;
    padding-left: 0.5rem;

    .desk-with-chairs {
      margin-left: 2rem;
      width: 8rem;
    }

    .chair-row {
      left: 2rem;
    }
  }

  &.sikkerhetogberedskap {
    grid-column: 14 / span 4;
    grid-row: 5 / span 6;
    border-width: 4px 0 0 0;

    .desk--half {
      width: 42%;
      margin-left: 0.9rem;
    }

    .bottom-desk-chair {
      margin-left: 1.1rem;
    }
  }

  &.digitalworkspace {
    grid-column: 18 / span 4;
    grid-row: 5 / span 6;
    border-width: 4px 0 0 0;
  }

  &.support {
    grid-column: 22 / span 5;
    grid-row: 5 / span 6;
    border-width: 4px 4px 0 0;
    padding-left: 0.4rem;
  }

  .desk-with-chairs {
    width: 8rem;
  }

  .chair-row {
    left: 2rem;
  }

  &.kjokken {
    grid-column: 11 / span 3;
    grid-row: 9 / span 2;
    border-width: 0 4px 0 0;
  }

  &.teknisk {
    grid-column: 11 / span 8;
    grid-row: 11 / span 6;
    border-width: 4px;
  }

  &.toilets {
    grid-column: 19 / span 7;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;
  }

  &.copyroom {
    grid-column: 26 / span 3;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;

    .name {
      transform: rotate(90deg);
      margin-top: 1rem;
    }
  }

  &.skrivetur {
    grid-column: 29 / span 3;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;

    .name {
      transform: rotate(90deg);
      margin-top: 1rem;
    }
  }

  &.lesetur {
    grid-column: 32 / span 3;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;

    .name {
      transform: rotate(90deg);
      margin-top: 1rem;
    }
  }

  &.tenketur {
    grid-column: 35 / span 3;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;

    .name {
      transform: rotate(90deg);
      margin-top: 1rem;
    }
  }

  &.studiotur {
    grid-column: 38 / span 3;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;

    .name {
      transform: rotate(90deg);
      margin-top: 1rem;
    }
  }

  &.elgtur {
    grid-column: 41 / span 5;
    grid-row: 11 / span 6;
    border-width: 4px 4px 4px 0;
  }

  &.utpatur {
    grid-column: 44 / span 15;
    grid-row: 5 / span 6;
    border-width: 4px;
  }

  &.test {
    grid-column: 14 / span 13;
    grid-row: 5 / span 6;
    border-width: 4px 4px 0 0;

    .desk-with-chairs-vertical {
      min-width: 1rem;
    }

    .chair-row {
      left: 0rem;
    }
  }

  &.support-glasswall {
    grid-column: 20 / span 7;
    grid-row: 11 / span 1;
    border-width: 2px 0 0 0;
  }

  &.togtur {
    grid-column: 31 / span 5;
    grid-row: 5 / span 6;
    border-width: 4px 0 4px 4px;
  }

  &.busstur {
    grid-column: 27 / span 4;
    grid-row: 5 / span 5;
    border-width: 4px 0;
  }

  &.battur {
    grid-column: 27 / span 4;
    grid-row: 5 / span 5;
    border-width: 4px 0;
  }

  &.flytur {
    grid-column: 31 / span 5;
    grid-row: 5 / span 6;
    border-width: 4px 0 4px 4px;
  }

  &.gåtur {
    grid-column: 36 / span 3;
    grid-row: 5 / span 6;
    border-width: 4px 0 4px 4px;
  }

  &.mobilitet {
    grid-column: 39 / span 6;
    grid-row: 5 / span 6;
    border-width: 4px 4px;
  }

  &.room-386 {
    grid-column: 45 / span 3;
    grid-row: 5 / span 6;
    border-width: 4px 0;
  }

  &.provetur {
    grid-column: 36 / span 12;
    grid-row: 5 / span 6;
    border-width: 4px 0 4px 4px;
  }

  &.ria {
    grid-column: 48 / span 11;
    grid-row: 5 / span 6;
    border-width: 4px 4px 0 4px;
  }

  &.inntekt {
    grid-column: 48 / span 3;
    grid-row: 5 / span 6;
    border-width: 4px 0 0 4px;

    > .desk-with-chairs-vertical {
      margin-left: -0.5rem;
    }
  }

  &.oppgjor {
    text-align: right;
    grid-column: 51 / span 8;
    grid-row: 5 / span 6;
    border-width: 4px 4px 0 0;

    > .desks {
      margin-left: -10px;
    }
  }

  &.mt {
    grid-column: 48 / span 6;
    grid-row: 5 / span 6;
    border-width: 4px 0 0 4px;

    .desk-with-chairs-vertical:first-child {
      margin: 0 0.18rem 0 -0.5rem;
    }
  }

  &.tvm {
    grid-column: 54 / span 5;
    grid-row: 5 / span 12;
    border-width: 4px 4px 0 0;
    padding-left: 1rem;
  }

  &.rundtur {
    grid-column: 24 / span 5;
    grid-row: 20 / span 5;
    border-width: 4px 4px;
  }

  &.varelager-standup {
    grid-column: 24 / span 5;
    grid-row: 20 / span 5;
    border-width: 4px 4px;
  }

  &.svipptur {
    grid-column: 37 / span 3;
    grid-row: 13 / span 2;
    border-width: 4px 0 0 4px;
  }

  &.jobbtur {
    grid-column: 37 / span 3;
    grid-row: 15 / span 3;
    border-width: 4px 0 4px 4px;
  }

  &.bomtur {
    grid-column: 40 / span 3;
    grid-row: 15 / span 3;
    border-width: 4px 0 4px 4px;
  }

  &.room-485 {
    grid-column: 40 / span 3;
    grid-row: 13 / span 5;
    border-width: 4px 0 4px 4px;
  }

  &.toilets-2 {
    grid-column: 43 / span 4;
    grid-row: 13 / span 5;
    border-width: 4px;
  }

  &.room-382 {
    grid-column: 40 / span 3;
    grid-row: 13 / span 2;
    border-width: 4px 0 0 4px;
  }

  &.ledelsen {
    align-self: end;
    grid-column: 11 / span 6;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 4px;
  }

  &.fellestjenester {
    align-self: end;
    grid-column: 11 / span 13;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 4px;

    .name {
      overflow-wrap: anywhere;
    }
  }

  &.trikketur {
    grid-column: 11 / span 6;
    grid-row: 20 / span 7;
    border-width: 4px 0 4px 4px;
  }

  &.marked {
    align-self: end;
    grid-column: 17 / span 4;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 0.5rem;
  }

  &.sykkeltur {
    grid-column: 17 / span 6;
    grid-row: 20 / span 7;
    border-width: 4px;
    padding-left: 0.5rem;
  }

  &.hr {
    align-self: end;
    grid-column: 21 / span 4;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 10px;
  }

  &.fergetur {
    grid-column: 23 / span 6;
    grid-row: 20 / span 7;
    border-width: 4px 4px 4px 0;
    padding-left: 10px;
  }

  &.produkt {
    align-self: end;
    grid-column: 9 / span 6;
    grid-row: 20 / span 7;
    border-width: 0 0 4px;
  }

  &.firklover1 {
    align-self: end;
    grid-column: 29 / span 4;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 10px;
  }

  &.firklover2 {
    align-self: end;
    grid-column: 33 / span 4;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 10px;
  }

  &.firklover3 {
    align-self: end;
    grid-column: 37 / span 4;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 10px;
  }

  &.litentur {
    grid-column: 41 / span 5;
    grid-row: 20 / span 7;
    border-width: 4px;
    padding-left: 10px;
  }

  &.firklover4 {
    align-self: end;
    grid-column: 46 / span 5;
    grid-row: 20 / span 7;
    border-width: 0;
    padding-left: 10px;
  }

  &.tegnetur {
    grid-column: 53 / span 6;
    grid-row: 20 / span 7;
    border-width: 4px 0 0 4px;
    padding-left: 10px;
  }

  &.personalisering {
    align-self: end;
    grid-column: 15 / span 5;
    grid-row: 20 / span 7;
    border-width: 0 0 4px;
  }

  &.tilbud {
    align-self: end;
    grid-column: 19 / span 5;
    grid-row: 20 / span 7;
    border-width: 0 0 4px;

    > .name {
      margin-left: 1rem;
    }
  }

  &.partner-1 {
    align-self: end;
    grid-column: 29 / span 5;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
  }

  &.partner-2 {
    align-self: end;
    grid-column: 33 / span 5;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 0.5rem;
  }

  &.betjent {
    align-self: end;
    grid-column: 29 / span 3;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;

    .name {
      margin-bottom: 1.5rem;
    }
  }

  &.designsystem {
    align-self: end;
    grid-column: 31 / span 5;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;

    > .name {
      text-align: right;
      margin-bottom: 1rem;
    }
  }

  &.avregning_and_inntektsmodell_oestlandet_1 {
    align-self: end;
    grid-column: 37 / span 5;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 0.75rem;
  }

  &.avregning_and_inntektsmodell_oestlandet_2 {
    grid-column: 42 / span 5;
    grid-row: 20 / span 5;
    border-width: 4px 4px 0;

    > .desks {
      margin-bottom: 6px;
    }
  }

  &.selvbetjent {
    align-self: end;
    grid-column: 35 / span 8;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    padding-left: 0.75rem;
  }

  &.room-472 {
    grid-column: 42 / span 5;
    grid-row: 20 / span 5;
    border-width: 4px;
  }

  &.ri {
    grid-column: 47 / span 12;
    grid-row: 20 / span 7;
    border-width: 0 4px 4px 0;
  }

  &.arkiv {
    align-self: end;
    grid-column: 47 / span 3;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
  }

  &.innkjop {
    align-self: end;
    grid-column: 50 / span 3;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    align-items: flex-end;
    padding-right: 0;

    .desk-with-chairs-vertical {
      float: right;
    }
  }

  &.regnskap {
    align-self: end;
    text-align: right;
    grid-column: 53 / span 6;
    grid-row: 20 / span 7;
    border-width: 0 0 4px 0;
    margin-left: -6px;
  }

  &.plattform {
    align-self: end;
    grid-column: 47 / span 12;
    grid-row: 20 / span 7;
    border-width: 0 4px 4px 0;

    .chair-row {
      justify-content: flex-end;
    }

    > .desks {
      justify-content: space-around;
    }
  }

  &.main-stairs {
    grid-column: 28 / span 7;
    grid-row: 13 / span 5;
    border-width: 4px;
  }
}
