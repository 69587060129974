@font-face {
  font-family: 'Nationale';
  src: url('Nationale-Light.be9d5622.eot');
  src: url('Nationale-Light.be9d5622.eot#iefix') format('embedded-opentype'),
    url('Nationale-Light.be126aee.woff2') format('woff2'),
    url('Nationale-Light.46fc0853.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-LightItalic.546fae61.eot');
  src: url('Nationale-LightItalic.546fae61.eot#iefix')
      format('embedded-opentype'),
    url('Nationale-LightItalic.e403feb5.woff2') format('woff2'),
    url('Nationale-LightItalic.754d74ec.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-Regular.41b3d0ba.eot');
  src: url('Nationale-Regular.41b3d0ba.eot#iefix') format('embedded-opentype'),
    url('Nationale-Regular.41f1095b.woff2') format('woff2'),
    url('Nationale-Regular.95e19bef.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-Italic.df25a9b8.eot');
  src: url('Nationale-Italic.df25a9b8.eot#iefix') format('embedded-opentype'),
    url('Nationale-Italic.8870ee52.woff2') format('woff2'),
    url('Nationale-Italic.d0dd9489.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-Medium.646a75d3.eot');
  src: url('Nationale-Medium.646a75d3.eot#iefix') format('embedded-opentype'),
    url('Nationale-Medium.472db572.woff2') format('woff2'),
    url('Nationale-Medium.02ee105a.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-MediumItalic.4b1fa9d2.eot');
  src: url('Nationale-MediumItalic.4b1fa9d2.eot#iefix')
      format('embedded-opentype'),
    url('Nationale-MediumItalic.9c9a8513.woff2') format('woff2'),
    url('Nationale-MediumItalic.1ca02e47.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-DemiBold.ecc92830.eot');
  src: url('Nationale-DemiBold.ecc92830.eot#iefix') format('embedded-opentype'),
    url('Nationale-DemiBold.56c78ee2.woff2') format('woff2'),
    url('Nationale-DemiBold.166e97bf.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nationale';
  src: url('Nationale-DemiBoldItalic.7772e5ec.eot');
  src: url('Nationale-DemiBoldItalic.7772e5ec.eot#iefix')
      format('embedded-opentype'),
    url('Nationale-DemiBoldItalic.29c4382b.woff2') format('woff2'),
    url('Nationale-DemiBoldItalic.3cdad450.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@charset "UTF-8";
.seventh.floor,
.third.floor,
.fourth.floor {
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(57, 1rem) 1rem;
  grid-template-rows: repeat(25, 1rem) 1rem;
  max-width: 52rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.seventh.floor > div,
.third.floor > div,
.fourth.floor > div {
  position: relative;
}
.block {
  background: repeating-linear-gradient(45deg, #656782, #656782 2px, #fff 0, #fff 8px);
  border: 4px solid #54568c;
}
.desk {
  border: 2px solid #54568c;
  height: 1.5rem;
}
.desk--half {
  border: 2px solid #54568c;
  height: 0.75rem;
}
.chair {
  border: 2px solid #54568c;
  width: 1rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 1px;
}
.chair:hover, .chair:focus, .chair--focused {
  background-color: #ff5959;
  border-color: #ff5959;
}
.chair:hover .person, .chair:focus .person, .chair--focused .person {
  display: block;
}
.chair-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.desk-with-chairs-vertical {
  display: flex;
}
.desk-with-chairs-vertical .chair-row {
  flex-direction: column;
}
.desk-with-chairs-vertical .chair {
  height: 1rem;
  width: 0.5rem;
}
.desk-with-chairs-vertical .desk {
  height: unset;
  width: 2rem;
}
.desk-with-chairs-vertical .desk--half {
  height: unset;
  width: 1rem;
}
.name {
  transition: 200ms all;
}
.module-west {
  grid-column: 11/span 15;
  grid-row: 13/span 5;
  border-width: 4px;
}
.hallway {
  grid-column: 9/span 5;
  grid-row: 1/span 5;
  border-width: 4px 4px 4px 0;
}
.fourth.floor > .hallway {
  border-width: 4px;
}
.hallway-stairs {
  grid-column: 11/span 3;
  grid-row: 6/span 3;
  border-width: 0 4px 4px;
}
.hallway-2 {
  grid-column: 48/span 11;
  grid-row: 13/span 7;
  border-width: 4px;
}
.outer-border {
  border: 4px solid #54568c;
  grid-column: 1/span 58;
  grid-row: 5/span 24;
  border-width: 0 4px 4px 4px;
}
.room {
  border: 4px solid #54568c;
  padding: 0.25rem;
}
.room:hover {
  background-color: #aeb7e2;
}
.room:hover .name {
  color: transparent;
}
.room.blatur {
  grid-column: 1/span 8;
  grid-row: 1/span 6;
  z-index: 2;
  border-width: 4px;
}
.room.langtur {
  grid-column: 1/span 8;
  grid-row: 1/span 10;
  z-index: 2;
  border-width: 4px;
}
.room.salg {
  padding-top: 0.5rem;
  grid-column: 1/span 8;
  grid-row: 1/span 8;
  z-index: 3;
  border-width: 4px 0 0 4px;
}
.room.salg .space {
  margin-top: 1rem;
}
.room.salg .desk-with-chairs {
  width: 100%;
}
.room.varelager {
  grid-column: 1/span 8;
  grid-row: 9/span 5;
  z-index: 2;
  border-width: 0 0 0 4px;
}
.room.varelager .desk-with-chairs {
  width: 100%;
}
.room.kunde {
  grid-column: 1/span 8;
  grid-row: 14/span 4;
  z-index: 1;
  border-width: 0 0 0 4px;
}
.room.kunde .desk-with-chairs {
  width: 100%;
}
.room.topptur {
  grid-column: 1/span 10;
  grid-row: 22/span 5;
  border-width: 0 0 4px 4px;
}
.room.drommetur {
  grid-column: 1/span 8;
  grid-row: 18/span 10;
  border-width: 4px;
}
.room.lobby {
  grid-column: 1/span 10;
  grid-row: 7/span 15;
  border-width: 0 0 4px 4px;
}
.room.ror {
  grid-column: 20/span 7;
  grid-row: 5/span 6;
  border-width: 4px 4px 0 0;
  padding-left: 0.5rem;
}
.room.ror .desk-with-chairs {
  margin-left: 2rem;
  width: 8rem;
}
.room.ror .chair-row {
  left: 2rem;
}
.room.sikkerhetogberedskap {
  grid-column: 14/span 4;
  grid-row: 5/span 6;
  border-width: 4px 0 0 0;
}
.room.sikkerhetogberedskap .desk--half {
  width: 42%;
  margin-left: 0.9rem;
}
.room.sikkerhetogberedskap .bottom-desk-chair {
  margin-left: 1.1rem;
}
.room.digitalworkspace {
  grid-column: 18/span 4;
  grid-row: 5/span 6;
  border-width: 4px 0 0 0;
}
.room.support {
  grid-column: 22/span 5;
  grid-row: 5/span 6;
  border-width: 4px 4px 0 0;
  padding-left: 0.4rem;
}
.room .desk-with-chairs {
  width: 8rem;
}
.room .chair-row {
  left: 2rem;
}
.room.kjokken {
  grid-column: 11/span 3;
  grid-row: 9/span 2;
  border-width: 0 4px 0 0;
}
.room.teknisk {
  grid-column: 11/span 8;
  grid-row: 11/span 6;
  border-width: 4px;
}
.room.toilets {
  grid-column: 19/span 7;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.copyroom {
  grid-column: 26/span 3;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.copyroom .name {
  transform: rotate(90deg);
  margin-top: 1rem;
}
.room.skrivetur {
  grid-column: 29/span 3;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.skrivetur .name {
  transform: rotate(90deg);
  margin-top: 1rem;
}
.room.lesetur {
  grid-column: 32/span 3;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.lesetur .name {
  transform: rotate(90deg);
  margin-top: 1rem;
}
.room.tenketur {
  grid-column: 35/span 3;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.tenketur .name {
  transform: rotate(90deg);
  margin-top: 1rem;
}
.room.studiotur {
  grid-column: 38/span 3;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.studiotur .name {
  transform: rotate(90deg);
  margin-top: 1rem;
}
.room.elgtur {
  grid-column: 41/span 5;
  grid-row: 11/span 6;
  border-width: 4px 4px 4px 0;
}
.room.utpatur {
  grid-column: 44/span 15;
  grid-row: 5/span 6;
  border-width: 4px;
}
.room.test {
  grid-column: 14/span 13;
  grid-row: 5/span 6;
  border-width: 4px 4px 0 0;
}
.room.test .desk-with-chairs-vertical {
  min-width: 1rem;
}
.room.test .chair-row {
  left: 0rem;
}
.room.support-glasswall {
  grid-column: 20/span 7;
  grid-row: 11/span 1;
  border-width: 2px 0 0 0;
}
.room.togtur {
  grid-column: 31/span 5;
  grid-row: 5/span 6;
  border-width: 4px 0 4px 4px;
}
.room.busstur {
  grid-column: 27/span 4;
  grid-row: 5/span 5;
  border-width: 4px 0;
}
.room.battur {
  grid-column: 27/span 4;
  grid-row: 5/span 5;
  border-width: 4px 0;
}
.room.flytur {
  grid-column: 31/span 5;
  grid-row: 5/span 6;
  border-width: 4px 0 4px 4px;
}
.room.gåtur {
  grid-column: 36/span 3;
  grid-row: 5/span 6;
  border-width: 4px 0 4px 4px;
}
.room.mobilitet {
  grid-column: 39/span 6;
  grid-row: 5/span 6;
  border-width: 4px 4px;
}
.room.room-386 {
  grid-column: 45/span 3;
  grid-row: 5/span 6;
  border-width: 4px 0;
}
.room.provetur {
  grid-column: 36/span 12;
  grid-row: 5/span 6;
  border-width: 4px 0 4px 4px;
}
.room.ria {
  grid-column: 48/span 11;
  grid-row: 5/span 6;
  border-width: 4px 4px 0 4px;
}
.room.inntekt {
  grid-column: 48/span 3;
  grid-row: 5/span 6;
  border-width: 4px 0 0 4px;
}
.room.inntekt > .desk-with-chairs-vertical {
  margin-left: -0.5rem;
}
.room.oppgjor {
  text-align: right;
  grid-column: 51/span 8;
  grid-row: 5/span 6;
  border-width: 4px 4px 0 0;
}
.room.oppgjor > .desks {
  margin-left: -10px;
}
.room.mt {
  grid-column: 48/span 6;
  grid-row: 5/span 6;
  border-width: 4px 0 0 4px;
}
.room.mt .desk-with-chairs-vertical:first-child {
  margin: 0 0.18rem 0 -0.5rem;
}
.room.tvm {
  grid-column: 54/span 5;
  grid-row: 5/span 12;
  border-width: 4px 4px 0 0;
  padding-left: 1rem;
}
.room.rundtur {
  grid-column: 24/span 5;
  grid-row: 20/span 5;
  border-width: 4px 4px;
}
.room.varelager-standup {
  grid-column: 24/span 5;
  grid-row: 20/span 5;
  border-width: 4px 4px;
}
.room.svipptur {
  grid-column: 37/span 3;
  grid-row: 13/span 2;
  border-width: 4px 0 0 4px;
}
.room.jobbtur {
  grid-column: 37/span 3;
  grid-row: 15/span 3;
  border-width: 4px 0 4px 4px;
}
.room.bomtur {
  grid-column: 40/span 3;
  grid-row: 15/span 3;
  border-width: 4px 0 4px 4px;
}
.room.room-485 {
  grid-column: 40/span 3;
  grid-row: 13/span 5;
  border-width: 4px 0 4px 4px;
}
.room.toilets-2 {
  grid-column: 43/span 4;
  grid-row: 13/span 5;
  border-width: 4px;
}
.room.room-382 {
  grid-column: 40/span 3;
  grid-row: 13/span 2;
  border-width: 4px 0 0 4px;
}
.room.ledelsen {
  align-self: end;
  grid-column: 11/span 6;
  grid-row: 20/span 7;
  border-width: 0 0 4px 4px;
}
.room.fellestjenester {
  align-self: end;
  grid-column: 11/span 13;
  grid-row: 20/span 7;
  border-width: 0 0 4px 4px;
}
.room.fellestjenester .name {
  overflow-wrap: anywhere;
}
.room.trikketur {
  grid-column: 11/span 6;
  grid-row: 20/span 7;
  border-width: 4px 0 4px 4px;
}
.room.marked {
  align-self: end;
  grid-column: 17/span 4;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 0.5rem;
}
.room.sykkeltur {
  grid-column: 17/span 6;
  grid-row: 20/span 7;
  border-width: 4px;
  padding-left: 0.5rem;
}
.room.hr {
  align-self: end;
  grid-column: 21/span 4;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 10px;
}
.room.fergetur {
  grid-column: 23/span 6;
  grid-row: 20/span 7;
  border-width: 4px 4px 4px 0;
  padding-left: 10px;
}
.room.produkt {
  align-self: end;
  grid-column: 9/span 6;
  grid-row: 20/span 7;
  border-width: 0 0 4px;
}
.room.firklover1 {
  align-self: end;
  grid-column: 29/span 4;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 10px;
}
.room.firklover2 {
  align-self: end;
  grid-column: 33/span 4;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 10px;
}
.room.firklover3 {
  align-self: end;
  grid-column: 37/span 4;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 10px;
}
.room.litentur {
  grid-column: 41/span 5;
  grid-row: 20/span 7;
  border-width: 4px;
  padding-left: 10px;
}
.room.firklover4 {
  align-self: end;
  grid-column: 46/span 5;
  grid-row: 20/span 7;
  border-width: 0;
  padding-left: 10px;
}
.room.tegnetur {
  grid-column: 53/span 6;
  grid-row: 20/span 7;
  border-width: 4px 0 0 4px;
  padding-left: 10px;
}
.room.personalisering {
  align-self: end;
  grid-column: 15/span 5;
  grid-row: 20/span 7;
  border-width: 0 0 4px;
}
.room.tilbud {
  align-self: end;
  grid-column: 19/span 5;
  grid-row: 20/span 7;
  border-width: 0 0 4px;
}
.room.tilbud > .name {
  margin-left: 1rem;
}
.room.partner-1 {
  align-self: end;
  grid-column: 29/span 5;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
}
.room.partner-2 {
  align-self: end;
  grid-column: 33/span 5;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 0.5rem;
}
.room.betjent {
  align-self: end;
  grid-column: 29/span 3;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
}
.room.betjent .name {
  margin-bottom: 1.5rem;
}
.room.designsystem {
  align-self: end;
  grid-column: 31/span 5;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
}
.room.designsystem > .name {
  text-align: right;
  margin-bottom: 1rem;
}
.room.avregning_and_inntektsmodell_oestlandet_1 {
  align-self: end;
  grid-column: 37/span 5;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 0.75rem;
}
.room.avregning_and_inntektsmodell_oestlandet_2 {
  grid-column: 42/span 5;
  grid-row: 20/span 5;
  border-width: 4px 4px 0;
}
.room.avregning_and_inntektsmodell_oestlandet_2 > .desks {
  margin-bottom: 6px;
}
.room.selvbetjent {
  align-self: end;
  grid-column: 35/span 8;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  padding-left: 0.75rem;
}
.room.room-472 {
  grid-column: 42/span 5;
  grid-row: 20/span 5;
  border-width: 4px;
}
.room.ri {
  grid-column: 47/span 12;
  grid-row: 20/span 7;
  border-width: 0 4px 4px 0;
}
.room.arkiv {
  align-self: end;
  grid-column: 47/span 3;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
}
.room.innkjop {
  align-self: end;
  grid-column: 50/span 3;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  align-items: flex-end;
  padding-right: 0;
}
.room.innkjop .desk-with-chairs-vertical {
  float: right;
}
.room.regnskap {
  align-self: end;
  text-align: right;
  grid-column: 53/span 6;
  grid-row: 20/span 7;
  border-width: 0 0 4px 0;
  margin-left: -6px;
}
.room.plattform {
  align-self: end;
  grid-column: 47/span 12;
  grid-row: 20/span 7;
  border-width: 0 4px 4px 0;
}
.room.plattform .chair-row {
  justify-content: flex-end;
}
.room.plattform > .desks {
  justify-content: space-around;
}
.room.main-stairs {
  grid-column: 28/span 7;
  grid-row: 13/span 5;
  border-width: 4px;
}
body {
  color: #181c56;
  font-family: "Nationale", sans-serif;
}
#main {
  display: flex;
  margin: 3rem;
}
.room {
  transition: all 300ms;
}
.room:hover .room-popup, .room:focus .room-popup, .room--focused .room-popup {
  display: block;
}
.room-popup {
  display: none;
  position: relative;
  bottom: 0;
  border-radius: 0.25rem;
  width: fit-content;
  background-color: white;
  color: #181c56;
  box-shadow: 0 0.125rem 1rem 0 rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  text-align: left;
  z-index: 200;
}
.room-popup img {
  max-width: 400px;
  border-radius: 0.25rem;
}
.panel {
  margin-right: 2rem;
}
.desks {
  display: flex;
}
.chair {
  border: 2px solid #54568c;
  width: 1rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 1px;
}
.chair:hover, .chair:focus, .chair--focused {
  background-color: #ff5959;
  border-color: #ff5959;
}
.chair:hover .person, .chair:focus .person, .chair--focused .person {
  display: block;
}
.chair--holiday {
  background-color: #ffcece;
  border-color: #d31b1b;
}
.person {
  display: none;
  position: relative;
  bottom: 0;
  border: 2px solid white;
  border-radius: 2px;
  width: fit-content;
  background-color: #181c56;
  color: white;
  min-width: 10rem;
  padding: 1rem;
  top: 1rem;
  left: 1rem;
  text-align: left;
  z-index: 100;
}
.person__id {
  font-size: 12px;
  float: right;
}
.person .holiday {
  font-size: 12px;
  margin-top: 2px;
}
header {
  color: #181c56;
}
.floors > h2 {
  float: right;
}
.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  opacity: 0.5;
}
input {
  background-color: #f8f8f8;
  border: 0;
  border-bottom: 2px solid #181c56;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.65625rem 1rem 0.53125rem 1rem;
}
.repo-link {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
@media only screen and (max-width: 770px) {
  #main {
    display: block;
    margin: 0.5rem;
  }
}
/*# sourceMappingURL=index.d3abd1f2.css.map */
